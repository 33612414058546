var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      attrs: {
        id: "pages-core-footer",
        absolute: "",
        color: "transparent",
        dark: ""
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _vm._l(_vm.links, function(link, i) {
                return _c(
                  "v-col",
                  {
                    key: i,
                    staticClass: "text-center",
                    attrs: { cols: "6", md: "auto" }
                  },
                  [
                    _c("a", {
                      staticClass: "mr-0",
                      attrs: {
                        href: link.href,
                        rel: "noopener",
                        target: "_blank"
                      },
                      domProps: { textContent: _vm._s(link.text) }
                    })
                  ]
                )
              }),
              _c("v-spacer", { staticClass: "hidden-sm-and-down" }),
              _c("v-col", { attrs: { cols: "12", md: "auto" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "body-1 font-weight-light pt-6 pt-md-0 text-center"
                  },
                  [
                    _vm._v(
                      "\n          © 2006-" +
                        _vm._s(new Date().getFullYear()) +
                        " ПОЛИГЛОТИКИ - Сеть детских языковых центров\n        "
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }